* {
    transition: all 0.5s ease;
}

/* NEW STYLES FOR CC... */

p.promoMessage1 { color: #0E3D2F;  font-size: 100%; margin-bottom: 7px; font-family: 'Montserrat', sans-serif; }
p.promoMessage2 { margin-top: 0; font-family: 'Montserrat', sans-serif; }
p.promoMessage2 a { color: #0E3D2F; text-decoration: underline; font-size: 90%; }

input#promoArea {
    font-size: 20px;
    border-radius: 15px;
    padding: 8px;
    position: relative;
    top: 2px;
    width: 191px;
    margin-right: 20px;    text-align: center;
}

input#promoArea::placeholder {color: #D7932E;}

    p.mintBoxRightMessage {
        font-family: 'Montserrat', sans-serif;
        font-size: 25px;
        color: #0E3D2F;
        margin-top: 0;
    }

.sub-text {
    font-size: 25px;
}

div.clear {
    background: none;
    border: 0;
    clear: both;
    display: block;
    float: none;
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

button {cursor: pointer; }

.buttonMessage{    font-size: 85%;
    color: black; font-family: 'Montserrat', sans-serif;}
.buttonMessage a {color: black; text-decoration: underline;}

body {
    /*background: url(panorama.jpg);*/
    color: black;
    margin: 0;
    padding: 0;
    font-family: 'Gilda Display', serif;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #0E3D2F;
    background-position-x: center;
}
.section-background-overlay {
 background:#0e3d2f; opacity: 0.85;min-height: 1000px;
}

a {
    color: #99a9a8;
    text-decoration: none;
}

.white {
    color: white;
}

.header img.thelogo{position: relative;     top: 0px;} 

.noCollectionSpacer { height:30px; }

.smaller {
    font-size: 80%;
}

.fixcaps {
    text-transform: initial !important;
}

div.MiddleSection {
    color: black;
    display: grid;
    @media(max-width: 765px) {
        margin-bottom: 1rem;
    }
}

div.GridItem {
    align-self: center;
    @media(max-width: 765px) {
        margin-bottom: 1rem;
    }
}

div.Welcome {
    text-transform: uppercase;
    font-size: 3vw;
    @media(max-width: 800px) {
        font-size: 4vw;
    }
}

div.MaxPerWallet {
    color: rgb(255 255 255 / 60%);
    font-size: 1.1rem;
    @media (max-width: 764px) {
        font-size: 4vw;
    }
}

div.PriceWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

div.PriceText {
    font-size: 2vw;
    @media(max-width: 764px) {
        font-size: 4vw;
    }
}

div.AmountWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

button.CounterBtnWrap {
    border: 0;
    background: transparent;
    color: white;
    font-size: 4vw;
    cursor: pointer;
    @media (max-width: 764px) {
        font-size: 10vw;
    }
}

div.CounterInput {
    background: transparent;
    border: solid white 2px;
    text-align: center;
    width: 15%;
    margin-right: 2rem;
    margin-left: 2rem;
    font-size: 1rem;
    padding: 1rem;
    @media( max-width: 764px) {
        width: 30%;
    }
}

input.slider {}

div.MintBtn {
    margin: 2rem 0;
    background: #3f5d13 !important;
    color: #0F0F0F !important;
    cursor: pointer;
    text-transform: uppercase;
    justify-self: self-start;
    border: 0 !important;
    font-size: 1.5vw;
    transition: all .2s;
}

.MintBtn:hover {
    color: white !important;
    background: black !important;
    transition: all .2s;
}

div.SoldFont {
    font-size: 1.4rem;
    color: white;
    @media (max-width: 765px) {
        font-size: 2rem;
    }
}

img.CollectionImage {
    width: 65%;
    border-radius: 15px;
}

div.LoadingFont {
    font-size: 1.5vw;
    color: white;
}

div.CountDownTimer {}

#pageLogo img {
    width: 100%;
}

#pageLogo {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 15px;
    left: 9%;
}



#saleStatus {
    font-size: 150%;
    color: #fff;
    text-decoration: overline;
    text-decoration-color: #00ffa7;
    text-decoration-thickness: from-font;
    text-decoration-style: wavy;
}

#theStatus {
    padding-left: 15px;
    color: white;
}

.pageHighlightColor {
    color: #3f5d13;
}

.pageHighlightColor2 {
    color: #6a624a;
}

#branding {
    padding-top: 20px;
}

#branding img {
    border: 2px solid black;
    -webkit-box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
    -moz-box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
    box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
}

#navButtons {
    margin-top: 20px;
}

img.navIcons {
    position: relative;
    top: 3px;
    left: -7px;
    width: 20px;
    height: 20px;
}

.logo-wallet {
    width: 90px;
    height: 90px;
}
.walletName{

    font-weight: 100;
    font-size: 150%;
    vertical-align: top;
    display: block;
}

div.ReactModal__Content.ReactModal__Content--after-open .providerChoices:hover { background:#3f5d13; color:white; }

#DAppArea button { margin-top:10px;  }
#totalPrice { margin-top:15px; margin-bottom:15px; font-family: 'Montserrat', sans-serif; }
#prepare button, #DAppArea button {
    background: #0e3d2f;
    color: white;
    border: initial;
    padding: 8px 20px 10px 20px;
    border-radius: 15px;
    text-transform: lowercase;
    transition: transform 200ms ease;
    font-weight: 100;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
}

#DAppArea .quantityQuestion {
    font-family: 'Montserrat', sans-serif;
    margin-bottom:6px;
}

#prepare hr {
    background: #d7932e;
    width: 30%;
    margin-top: 27px;
    height: 1px;
}

#prepare button:hover, #DAppArea button:hover {     background: #0949c9;
    color: white;
    transform: translate(0px, -2px); }

div.ReactModal__Content.ReactModal__Content--after-open {    background: #ffffff !important;    text-align: center; border-radius: 15px !important; padding-top: 10px !important; border: 1px gray solid !important; box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.65);
    -webkit-box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.65);}
div.ReactModal__Content.ReactModal__Content--after-open h2 {  color: black !important;     font-size: 160%;     margin-bottom: 10px;    margin-top: 6px;     font-family: 'Readex Pro', sans-serif;
}
div.ReactModal__Content.ReactModal__Content--after-open button {    background: white;    border: initial;    padding: 5px;    border-radius: 15px;     margin-bottom: 15px; }
div.ReactModal__Content.ReactModal__Content--after-open .providerChoices {     width: 100%;  margin-bottom: 10px; background: #fff;  border: 1px solid gray;    border-radius: 15px; margin-top: 10px; }

    .topBanner img {

    }
    
    .topBanner {
        width:100%;
        padding: 0px;
        padding-bottom: 0;
        background-attachment: fixed;
        text-align: center;
    }

.topBannerWrapper { 
    width:90%; 
    max-width: 1200px;   
     margin-left: auto;  
       margin-right: auto; }

.topBannerLeft { float: left;
    width: 20%; }

.topBannerRight { width: 80%;
    float: right;
    margin: 0;
    padding: 0;
    text-align: right; 
    padding-top: 4%; }

    .topBannerRight a { color: #3f5d13;
        padding-left: 9px;
        padding-right: 9px;
        font-size: 100%;
        background: #ffe80069;
        margin-left: 15px;
        border-radius: 15px;}
    .topBannerRight a:hover { color: #ffffff; background: #3f5d13; }

.topBannerButton {
    padding: 3px 10px 3px 10px;
    border-radius: 15px;
    box-shadow: 3px 3px 11px rgb(0 0 0 / 40%);
    -webkit-box-shadow: 3px 3px 11px rgb(0 0 0 / 40%);
    -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
    background: black;
    margin: 20px 20px 20px 20px;
}

.topBannerButton:hover {
    background: #d54aff;
}

.mintBoxRight h2 {
    font-size: 50px;
    color: #0E3D2F;
    margin-top: 0;
    margin-bottom: 10px;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
}

#DAppArea h2 {
    color: white;
    font-size: 275%; 
    margin-top: 0;    font-weight: 100; 
}

#DAppArea h3 {
    color: #0E3D2F;
    font-style: normal;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
}

#walletButton {}

.white { color: white; }

.qty { color: #364050; }

button:hover .qty { color: white; }

.welcome {
    text-align: center;
    font-size: 135%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 30px;
    MARGIN-TOP: 0;
    letter-spacing: 1px;
}

span.copyrightNotice {
    font-size: 65%;
}

.droplogo { text-align:center; }
.droplogo img { width: 150px; }

.allButMobile  {text-align: center; }
.allButMobile img, img.mobileOnly {max-width: 90%; border-radius: 15px; width: 90%;    margin-bottom: 15px;
    -webkit-box-shadow: 3px 3px 10px 3px #7d7a7a;
    -moz-box-shadow: 3px 3px 10px 3px #7d7a7a;
    box-shadow: 3px 3px 10px 3px #7d7a7a; border: 3px solid #1c58c0;
}


div.mintBoxWrapper {     color: black;     padding: 20px;
    background: #E7F7DD;
    width: 90%;
    display: flex;
    overflow: auto;
    margin: 3% auto 20px auto;
    max-width: 1200px;
    /*box-shadow: 0 0 0px 2px #abca72, 0 0 0px 0px #e7f8dd, 0 0 0px 0px #8a2be2, 0 0 0px 5px #0949c9;*/
    border-radius: 34px;
}


div.aboveMintBox {  
    width: 90%;
    display: flex;
    overflow: auto;
    margin: 15px auto 20px auto;
    max-width: 1200px;

}
.supplyInfo {    font-size: 125%;}
div.mintBoxLeft {
    width: 44%;
    padding-left: 0;
    padding-top: 2%;
    display: block;
    justify-content: center;
    align-items: start;
    align-content: center;
}

div.mintBoxRight {
    width: 56%;
    padding: 20px;
    float: left;
    text-align: center;
    padding: 10px 2% 0 2%;
}

.mobileOnly {
    display: none;
}

#currentMintCount {}

/* YOUR COLLECTION */

.collectionWrapper {
    padding: 1% 2% 3% 2%;
    background: #1c1c1c;
    text-align: center;
    background: black;
    background-image: url(https://yearofthewoman.io/wp-content/uploads/2022/02/4907157-scaled.jpg?id=251) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

input.pageButton span {}

.collectionWrapper h2 { font-size: 225%; }

.collectionRow {     display: grid;    grid-template-columns: 1fr 1fr 1fr;    gap: 3rem;
    margin-top: 2rem;    max-width: 1700px;    margin-left: auto;    margin-right: auto; }

#renderCollection {     display: grid;    grid-template-columns: 1fr 1fr 1fr;    gap: 3rem;
    margin-top: 2rem;    max-width: 1700px;    margin-left: auto;    margin-right: auto; }

.collectionItem {}

.collectionImage {     width: 75%;    border-radius: 15px;    vertical-align: middle;    border: 4px solid saddlebrown; }

.collectionTokenTitle {     margin-top: 1rem;    color: saddlebrown;     font-size: 125%; }

#prepare p {
    font-size: 15px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    color: #0e3d2f;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
}

.header {   padding-top: 50px;
    max-width: 1200px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;} 

.headerLeft {float:left; width:44%; padding-left:5%; text-align:left; }

.headerRight {float: right;    width: 45%;    padding-right: 5%;    text-align: right; } 
a.pageButton.logowords { color:white; font-weight: 100; font-size: 20px;padding-left: 20px;    padding-right: 20px;
    padding-top: 6px; }

.footer {     border-top: 1px solid black;    width: 100%;    padding: 0;    text-align: center;    color: black;
    padding-bottom: 8px;     padding-top: 7px;    background: white;    bottom : 0px;       height : 40px;  
    margin-top : 40px;      text-align: center;      vertical-align: middle;     position:fixed;    width:100%; }

    a.pageButton {
        /* background: #3f5d13; */
        color: white;
        padding: 5px 5px 5px 11px;
        margin: 0 2% 0 3%;
        white-space: nowrap;
        position: relative;
        transition: transform ease 200ms;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid white;
        font-weight: 100;
        /* box-shadow: 0 0 0px 2px #abca72, 0 0 0px 0px #e7f8dd, 0 0 0px 0px #8a2be2, 0 0 0px 5px #0949c9; */
    }

a.pageButton:hover {     background: #0949c9;    color: white;    transform: translate(0, -2px); }

.bannerButton {     padding: 9px 13% 8px 13%;    line-height: 250%;    border-radius: 15px;    box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);     -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    white-space: nowrap; }

.bannerButton:hover {     background: #d54aff; }

#HeaderButtons {     margin-top: 20px;    position: absolute;    top: 1%;    right: 6%; }

.header img {
    position: relative;
    top: 3px;
    left: -4px;
    width: 20px;
}

.headerLeft img { width: 150px; }

.footer img {
    position: relative;
    top: 3px;
    left: -4px;
    width: 20px;
}

.credits {
    background: #778384;
    text-align: center;
    font-size: 80%;
    letter-spacing: 1px;
    color: white;
}

.credits a {
    color: black;
}
.supplyInfo { font-weight:bold; }
.larger {
    font-size: 200%;
    font-weight: 100;
    letter-spacing: initial;
}

input[type=range] {
    width: 30%;
    margin: 8px 0;
    background-color: transparent;
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    background: #ffffff;
    border: 1px solid #838585;
    border-radius: 15px;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
    margin-top: -9px;
    width: 33px;
    height: 22px;
    background: #a7d74e;
    border: 4px solid #3d4928;
    border-radius: 15px;
    cursor: pointer;
    -webkit-appearance: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ffffff;
}

input[type=range]::-moz-range-track {
    background: #ffffff;
    border: 1px solid #dbd58f;
    border-radius: 15px;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-moz-range-thumb {
    width: 33px;
    height: 22px;
    background: gold;
    border: 4px solid black;
    border-radius: 15px;
    cursor: pointer;
}

input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 12.1px 0;
    color: transparent;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-ms-fill-lower {
    background: #ebebeb;
    border: 1px solid #dbd58f;
    border-radius: 50px;
}

input[type=range]::-ms-fill-upper {
    background: #ffffff;
    border: 1px solid #dbd58f;
    border-radius: 50px;
}

input[type=range]::-ms-thumb {
    width: 33px;
    height: 22px;
    background: gold;
    border: 4px solid black;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
}

input[type=range]:focus::-ms-fill-lower {
    background: #ffffff;
}

input[type=range]:focus::-ms-fill-upper {
    background: #ffffff;
}

@media (min-width: 1400px) {
    #renderCollection {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 1137px) {

    .topBanner img { width: 175%; }
    .topBannerRight a { }

    .headerLeft { width: 30%; padding-left: 2%; }
    .headerRight { width: 65%; padding-right: 0%; }

}

@media (max-width: 800px) {
    div.mintBoxLeft { width: 0%; padding: 0; }
    div.mintBoxRight { width: 100%; padding: 20px; }
    .mobileOnly { display: block; margin: 10px auto 20px auto; max-width: 60%; }
    #renderCollection { grid-template-columns: 1fr 1fr; }
    #amountSold {}
    .topBanner { background: #ffffff; min-height: 80px;  }
    .footer { display:none; }
    .topBannerRight, .topBannerLeft { width: 100%; float: initial; text-align: center; }
    .topBanner img { width: 90%; }
    .allButMobile { display:none; }
    a.pageButton { padding: 2px 2% 2px 2%;  margin: 0 1% 0 1%; font-size: 75%; }
    .header { height: 30px; padding-top: 30px; }

   .topBanner { min-height: 50px; }
   .headerLeft { width:100%; text-align:center; padding:0; display:block; }
   .headerRight { width:100%; text-align:center; padding:0; display:block; padding-top: 20px;}
   .headerRight img { top: 2px; left: 0px; width: 18px; }

}
